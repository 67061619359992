import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import { Alert, Box, Checkbox, Chip, CircularProgress, Link as MuiLink, Typography } from '@mui/material';
import { ColumnDef, RowSelectionState } from '@tanstack/react-table';
import React, { SetStateAction, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { UserAccess } from '../../../common/constants/access';
import { PaginatedResponse, UserMin } from '../../../common/dto/common.dto';
import {
  CoordinatesDto,
  DateRange,
  DurationValue,
  FormDataValueDto,
  FormDataViewDto,
  FormValueDto,
  IssueAnswerTypeEnum,
} from '../../../common/dto/formdata.dto';
import { FieldType, TemplateElementView, TemplateViewDto } from '../../../common/dto/template.dto';
import FormatDate from '../../../components/common/DateFormat';
import ShowMoreText from '../../../components/common/ShowMoreText';
import { UserAvatar } from '../../../components/common/UserAvatar';
import DataTable, { debugCols, ListTypeEnum } from '../../../components/data-table/data-table';
import { getDateFormat } from '../../../components/elements/formdata-elements/FormDataDateTimeField';
import { formatDurationValue } from '../../../components/elements/view-elements/ViewDurationElement';
import { FlexRow } from '../../../components/styled/Flex';
import { SearchFilterProps } from '../../../hook/useSearch';
import { useAppSelector } from '../../../redux/hooks';
import { checkMultiLocAccess } from '../../../redux/slices/userInfo.slice';

interface Props {
  isLoading: boolean;
  template: TemplateViewDto;
  data?: PaginatedResponse<FormDataViewDto>;
  rowSelection?: RowSelectionState;
  setRowSelection?: React.Dispatch<SetStateAction<RowSelectionState>>;
  setSearchFilter: (value: React.SetStateAction<SearchFilterProps>) => void;
}

const NormalReportDataTable = (props: Props) => {
  const { template } = props;
  const locationIds = template.locations.map((l) => l.id);
  const hasViewAccess = useAppSelector(checkMultiLocAccess(UserAccess.REPORT_VIEW, locationIds));

  const [hasScoring, setHasScoring] = useState(false);

  const additionalColumns = useMemo<ColumnDef<FormDataViewDto>[]>(() => {
    const cols: ColumnDef<FormDataViewDto>[] = [];
    if (template != null) {
      setHasScoring(template.isScoringPresent);
      template.pages.forEach((page) => {
        page.elements.forEach((element) => {
          if (![FieldType.page, FieldType.fileUpload, FieldType.imageUpload, FieldType.signature, FieldType.label].includes(element.fieldType)) {
            let enableSorting = false;
            if (
              [
                FieldType.singleLine,
                FieldType.multiLine,
                FieldType.numeric,
                FieldType.oilLevel,
                FieldType.fuel,
                FieldType.temperature,
                FieldType.issue,
                FieldType.datetime,
                FieldType.customPicker,
              ].includes(element.fieldType)
            ) {
              enableSorting = true;
            }
            cols.push({
              id: element.elementId,
              header: element.title,
              size: 130,
              enableSorting,
              accessorFn: (row) => {
                const elementData = row.data.find((d) => d.elementId === element.elementId);
                return elementData;
              },
              cell: ({ cell: { getValue } }) => (
                <ValueRenderer
                  value={(getValue() as FormDataValueDto)?.value}
                  element={element}
                  issueAnswerType={(getValue() as FormDataValueDto)?.issueAnswerType}
                />
              ),
            });
          }
        });
      });
    }
    return cols;
  }, [template]);

  const columns = useMemo<ColumnDef<FormDataViewDto>[]>(
    () => [
      {
        id: 'select',
        size: 10,
        header: ({ table }) => (
          <Checkbox
            data-automation="row-all"
            sx={{
              padding: 0,
            }}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            data-automation={`row-${row.index}`}
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
        width: 130,
        minWidth: 100,
        maxWidth: 300,
      },
      ...debugCols<FormDataViewDto>(),
      {
        id: 'reportNo',
        header: 'Report No',
        size: 50,
        accessorFn: (row) => {
          return { reportNo: row.reportNo, id: row.id };
        },
        cell: ({ cell: { getValue } }) => <Link to={`/app/reports/view/${(getValue() as any).id}`}>{(getValue() as any).reportNo}</Link>,
        width: 130,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        id: 'score',
        header: 'Score',
        accessorKey: 'score',
        size: 50,
        cell: ({ cell: { getValue } }) => <ScoringRenderer value={getValue() as number} />,
        width: 130,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        id: 'createdAt',
        header: 'Completed At',
        accessorKey: 'createdAt',
        size: 200,
        cell: ({ cell: { getValue } }) => <FormatDate date={getValue() as string} />,
        width: 130,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        id: 'createdBy',
        header: 'Completed By',
        accessorKey: 'createdBy',
        size: 200,
        cell: ({ cell: { getValue } }) => <UserAvatar user={getValue() as UserMin} />,
        width: 130,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        id: 'templateVersion',
        header: 'Template Version',
        accessorKey: 'templateVersion',
        size: 200,
        width: 130,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        id: 'audit',
        header: 'Duration',
        accessorKey: 'duration',
        size: 200,
        enableSorting: false,
        cell: ({ cell: { getValue } }) => <DurationRenderer duration={getValue() as string} />,
        width: 130,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        id: 'coordinates',
        header: 'Coordinates',
        accessorKey: 'coordinates',
        size: 200,
        enableSorting: false,
        cell: ({ cell: { getValue } }) => <CoordinatesRenderer coordinates={getValue() as CoordinatesDto} />,
        width: 130,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        id: 'asset.name',
        header: 'Asset Name',
        accessorFn: (row) => row.asset?.name,
        width: 130,
        minWidth: 100,
        maxWidth: 300,
      },
      {
        id: 'asset.uniqueCode',
        header: 'Asset Unique Code',
        accessorFn: (row) => row.asset?.uniqueCode,
        width: 130,
        minWidth: 100,
        maxWidth: 300,
      },
      ...additionalColumns,
    ],
    [additionalColumns],
  );

  return (
    <>
      {/* {!error && hasViewAccess && (
        <DataTable
          loading={isLoading}
          columns={columns}
          data={data}
          setSearchFilter={setSearchFilter}
          hideActions
          scoring={hasScoring}
          rowSelection={props.rowSelection}
          setRowSelection={props.setRowSelection}
        />
      )} */}
      {hasViewAccess && (
        <DataTable
          loading={props.isLoading}
          columns={columns}
          data={props.data}
          setSearchFilter={props.setSearchFilter}
          hideActions
          scoring={hasScoring}
          rowSelection={props.rowSelection}
          setRowSelection={props.setRowSelection}
          listType={ListTypeEnum.REPORT}
          templateId={template.id}
        />
      )}
    </>
  );
};

export default NormalReportDataTable;

type ValueRendererProps = {
  value?: FormValueDto;
  element: TemplateElementView;
  issueAnswerType?: IssueAnswerTypeEnum;
};

const ValueRenderer = (props: ValueRendererProps) => {
  // console.log('ValueRenderer', props);
  if (props.value == null || props.value === '') {
    return null;
  }

  if (typeof props.value === 'number' || typeof props.value === 'string') {
    if (props.element.fieldType === FieldType.datetime) {
      return <FormatDate date={props.value as string} format={getDateFormat(props.element.config)} />;
    }
    if (props.element.fieldType === FieldType.issue) {
      let color: 'default' | 'success' | 'error' = 'default';
      if (props.issueAnswerType === IssueAnswerTypeEnum.GREEN) {
        color = 'success';
      } else if (props.issueAnswerType === IssueAnswerTypeEnum.RED) {
        color = 'error';
      }

      return <Chip label={props.value as string} variant="outlined" color={color} sx={{ minWidth: 70, fontWeight: 'bold', textTransform: 'uppercase' }} />;
    }
    if (typeof props.value === 'number') {
      return <Typography variant="body1">{props.value}</Typography>;
    }
    return <ShowMoreText textLimit={50} text={props.value as string} />;
  }
  if (Array.isArray(props.value)) {
    if (props.element.fieldType === FieldType.radio && props.value.length === 1) {
      return <Typography variant="body1">{props.value[0] as string}</Typography>;
    }
    return (
      <FlexRow columnGap={1}>
        {props.value.map((val, ind) => (
          <Chip key={`ind-${ind}`} label={val as string} variant="outlined" color="default" sx={{ minWidth: 50 }} />
        ))}
      </FlexRow>
    );
  }
  if (typeof props.value === 'object') {
    if (props.element.fieldType === FieldType.datetime) {
      return (
        <Box>
          <FormatDate date={(props.value as DateRange).from as string} format={getDateFormat(props.element.config)} /> -
          <FormatDate date={(props.value as DateRange).to as string} format={getDateFormat(props.element.config)} />
        </Box>
      );
    }
    if (props.element.fieldType === FieldType.duration) {
      return <Box>{formatDurationValue(props.value as DurationValue, props.element.config.duration?.displayFormat)}</Box>;
    }
  }

  return (
    <>
      <Alert severity="error">No value rendered matched</Alert>
    </>
  );
};

type DurationRendererProps = {
  duration: string;
};
const DurationRenderer = (props: DurationRendererProps) => {
  return (
    <FlexRow gap={0.5} alignItems="center">
      <AccessTimeIcon sx={{ color: (theme) => theme.palette.grey[500] }} fontSize="medium" />
      <Typography variant="body1">{props.duration}</Typography>
    </FlexRow>
  );
};

type ScoringRendererProps = {
  value?: number | null;
};
const ScoringRenderer = ({ value }: ScoringRendererProps) => {
  if (value == null) return <></>;

  return (
    <Box display="flex" alignItems="center">
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" size={32} value={100} thickness={5} sx={{ color: (theme) => theme.palette.grey[300] }} />
        <CircularProgress
          variant="determinate"
          size={32}
          value={value}
          thickness={5}
          color="primary"
          sx={{ top: 0, left: 0, bottom: 0, right: 0, position: 'absolute' }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">{`${Math.ceil(value)}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

type CoordinatesRendererProps = {
  coordinates: CoordinatesDto;
};
const CoordinatesRenderer = (props: CoordinatesRendererProps) => {
  return (
    <Box>
      {props.coordinates ? (
        <MuiLink
          target="_blank"
          sx={{ alignItems: 'center', cursor: 'pointer' }}
          href={`http://www.google.com/maps/place/${props.coordinates.lat},${props.coordinates.long}`}
        >
          <PlaceOutlinedIcon fontSize="large" />
        </MuiLink>
      ) : (
        <Typography variant="body1">-</Typography>
      )}
    </Box>
  );
};
